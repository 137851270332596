import React, {useEffect} from 'react';
import {init} from '../misc/scene';

function PromoBlock({title, intro}) {

    let [lockState, setLockState] = React.useState(false);

    useEffect(() => {
        if (!lockState) {
            setLockState(true);
            init();
        }
        // eslint-disable-next-line
    }, []);
    return (
        <div className={"block-promo"} id={"block-intro"}>
            <div id={"animation"} className={"intro-background"}>

            </div>
            <div className={"intro-dim"}>
                <div className={"resizeable-container"}>
                    <div style={{
                        height: "64px"
                    }}></div>
                    <div className={"intro-block"}>
                        <h1 className={"promo-title"}>{title}</h1>
                        <br/>
                        <p className={"promo-paragraph"}>{intro}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromoBlock;