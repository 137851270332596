import React from 'react';
import Header from "../blocks/Header";
import Footer from "../blocks/Footer";
import PromoBlock from "../blocks/PromoBlock";
import TextBlock from "../blocks/TextBlock";

function Join() {
    return (
        <div>
            <Header/>
            <PromoBlock title={"Join Us"} intro={"Are you a full-time student and looking for new opportunities, projects and experience? Want to start your project but have no team? Or just lear something new? Here you can find useful information that will help you to become a member."}/>
            <TextBlock>
                <div className={"text-container"}>
                    <h3>Candidate Requirements</h3>
                    <ul>
                        <li>Be at least 18 years old and enrolled in a full-time study of Bachelor or Master program (Preferred Computer Science).</li>
                        <li>You must have at least one of the following skills: Web development (Pure html/js/css or some framework like React or Angular), Android application development (Java/Kotlin), MS-Windows application development (VC ++, C, C #), Python development, Unix (Linux)-like systems and development software for them, skill and experience with Arduino / Raspberry PI, networks management, 3D-modeling.</li>
                        <li>It will be an advantage if you have marketing or graphic design skills.</li>
                        <li>Strong understanding of data structures and algorithms.</li>
                        <li>Time management and communication.</li>
                        <li>A valid Google account.</li>
                        <li>English (at least B1).</li>
                    </ul>
                    <div className={"note"}>
                        <span className={"material-symbols-outlined"}>info</span>
                        &nbsp;&nbsp;&nbsp;
                        <div>
                            <span>If you meet the following requirements, you can start your application by clicking the button below.</span>
                            <br/><br/>
                            <a target={"_blank"} href={"https://docs.google.com/forms/d/e/1FAIpQLSfxk4reH3a4cqmWH7B5x08Dl6ccQGrPzGVMHeJxbRU8pKPRLQ/viewform"} rel="noreferrer"><button className={"btn-material-filled btn-material"}>Start application</button></a>
                        </div>
                    </div>
                    <br/>
                    <div className={"warning"}>
                        <span className={"material-symbols-outlined"}>warning</span>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                            The request will be considered by the administrator personally. It may take several days. Please note that not everyone can be accepted into the project. Reception and consideration of applications is carried out by the administration at its own discretion. If your request was rejected, you can submit another one no earlier than 1 month later. All requests submitted during this period will be ignored. Abuse of the number of applications is punishable by blocking the account for 1 month.
                        </span>
                    </div>
                </div>
            </TextBlock>
            <Footer/>
        </div>
    );
}

export default Join;