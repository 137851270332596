import React from 'react';
import Header from "../blocks/Header";
import Footer from "../blocks/Footer";
import PromoBlock from "../blocks/PromoBlock";

function NotFound() {
    return (
        <>
            <Header/>
            <PromoBlock title={"404"} intro={"Page not found"}/>
            <Footer/>
        </>
    );
}

export default NotFound;