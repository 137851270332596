import React from 'react';
import Header from "../../blocks/Header";
import ProjectBlockTemplate from "../../blocks/ProjectBlockTemplate";
import ivisit_1 from "../../res/ivisit_1.webp";
import ivisit_2 from "../../res/ivisit_2.webp";
import ivisit_3 from "../../res/ivisit_3.webp";
import ivisit_4 from "../../res/ivisit_4.webp";
import Footer from "../../blocks/Footer";

function IVisit(props) {
    return (
        <div>
            <Header/>
            <ProjectBlockTemplate title={"iVisit"}
                                  cover={"https://teslasoft.org/res/new/kosice.jpg"}
                                  icon={"https://teslasoft.org/res/new/ivisit.png"}
                                  desc={"iVisit is your personal city guide in the palm of your hand! This easy-to-use app helps you explore cities like never before. With live suggestions and rich information about popular places, you can plan your itinerary with ease. Build your own custom route and make the most of your trip. Experience the best a city has to offer with iVisit."}>
                <h3>Features</h3>
                <ul>
                    <li>Local events</li>
                    <li>Public transport schedule</li>
                    <li>Rent a vehicle*</li>
                    <li>Book a hotel</li>
                    <li>Restaurants</li>
                    <li>Favorite places</li>
                    <li>Personal route</li>
                    <li>Weather</li>
                    <li>Places near you</li>
                </ul>
                <p>* May not be supported in some countries.</p>
                <h3>Download and run</h3>
                <br/>
                <div>
                    <button style={{
                        marginBottom: "16px",
                        marginRight: "16px"
                    }} className={"btn-material-tonal btn-material"}>In development...</button>
                </div>
                <h3>Screenshots</h3>
                <br/>
                <div className={"horizontal-scroller"}>
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={ivisit_1}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={ivisit_2}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={ivisit_3}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={ivisit_4}/>
                </div>
            </ProjectBlockTemplate>
            <Footer/>
        </div>
    );
}

export default IVisit;