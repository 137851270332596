import Home from "./pages/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFound from "./pages/NotFound";
import Debug from "./pages/Debug";
import Privacy from "./pages/Privacy";
import ToS from "./pages/ToS";
import Cookies from "./pages/Cookies";
import Contact from "./pages/Contact";
import Join from "./pages/Join";
import Sitemap from "./pages/Sitemap";
import FAQ from "./pages/FAQ";
import Projects from "./pages/Projects";
import SpeakGPT from "./pages/projects/SpeakGPT";
import IVisit from "./pages/projects/iVisit";
import TeslasoftId from "./pages/projects/TeslasoftId";

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} exact/>
            <Route path="/debug" element={<Debug />} exact/>
            <Route path="/projects/speakgpt" element={<SpeakGPT />} exact/>
            <Route path="/projects/ivisit" element={<IVisit />} exact/>
            <Route path="/projects/teslasoft-id" element={<TeslasoftId />} exact/>
            <Route path="/privacy" element={<Privacy />} exact/>
            <Route path="/tos" element={<ToS />} exact/>
            <Route path="/cookies" element={<Cookies />} exact/>
            <Route path="/contact" element={<Contact />} exact/>
            <Route path="/join" element={<Join />} exact/>
            <Route path="/sitemap" element={<Sitemap />} exact/>
            <Route path="/faq" element={<FAQ />} exact/>
            <Route path="/projects" element={<Projects />} exact/>
            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
