import React from 'react';

function MissionBlock() {
    return (
        <div className={"block-mission"} id={"block-mission"}>
            <div className={"resizeable-container"}>
                <h1 className={"header-subtitle"}>Our Mission</h1>
                <p className={"mission-paragraph"}>At Teslasoft, we believe in the power of innovation to transform
                    lives. As a dynamic and growing tech company, we specialize in developing state-of-the-art
                    applications and solutions designed to simplify everyday challenges and enhance the quality of life
                    for our users.</p>
                <br/>
                <p className={"mission-paragraph"}>Our passionate team is committed to delivering excellence, blending
                    creativity with advanced technology to craft products that are intuitive, functional, and impactful.
                    From initial concepts to final execution, we place the user experience at the heart of everything we
                    do.</p>
                <br/>
                <p className={"mission-paragraph"}>Driven by a vision to shape the future of digital innovation,
                    Teslasoft is more than a company—we're your partner in navigating the ever-evolving world of
                    technology. Join us as we turn ideas into solutions that matter.</p>
                <br/><br/>
            </div>
        </div>
    );
}

export default MissionBlock;