import React from 'react';
import Header from "../blocks/Header";
import PromoBlock from "../blocks/PromoBlock";
import Footer from "../blocks/Footer";
import TextBlock from "../blocks/TextBlock";

function Sitemap() {
    return (
        <div>
            <Header/>
            <PromoBlock title={"Sitemap"} intro={"This place is intended for robots to properly index our site."}/>
            <TextBlock>
                <div className={"text-container"}>
                    <ul>
                        <li>
                            <a className={"header-link"} href={"/"}>Home</a>
                            <ul>
                                <li>
                                    <a className={"header-link"} href={"/contact"}>Contact</a>
                                </li>
                                <li>
                                    <a className={"header-link"} href={"/join"}>Join Us</a>
                                </li>
                                <li>
                                    <a className={"header-link"} href={"/projects"}>Projects</a>
                                    <ul>
                                        <li>
                                            <a className={"header-link"} href={"/projects/speakgpt"}>SpeakGPT</a>
                                        </li>
                                        <li>
                                            <a className={"header-link"} href={"/projects/ivisit"}>iVisit</a>
                                        </li>
                                        <li>
                                            <a className={"header-link"} href={"/projects/teslasoft-id"}>Teslasoft ID</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a className={"header-link"} href={"/privacy"}>Privacy Policy</a>
                                </li>
                                <li>
                                    <a className={"header-link"} href={"/tos"}>Terms of Service</a>
                                </li>
                                <li>
                                    <a className={"header-link"} href={"/cookies"}>Cookies</a>
                                </li>
                                <li>
                                    <a className={"header-link"} href={"/faq"}>FAQ</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </TextBlock>
            <Footer/>
        </div>
    );
}

export default Sitemap;