import React from 'react';
import Header from "../blocks/Header";
import PromoBlock from "../blocks/PromoBlock";
import Footer from "../blocks/Footer";
import TextBlock from "../blocks/TextBlock";
import {Link} from "react-router-dom";

function Privacy() {
    return (
        <div>
            <Header />
            <PromoBlock title={"Privacy"} intro={"Privacy starts with understanding how developers collects and use your data. You can find more info about it on this page."} />
            <TextBlock>
                <div className={"text-container"}>
                    <h3>Common terminology</h3>

                    <p>We - organization "Teslasoft" registered at Južná trieda, Košice, Slovakia (Registration number:
                        55545386, D-U-N-S: 933739642).</p>
                    <p>Your data - all data, collected by us or provided by you.</p>
                    <p>Teslasoft ID - your account in our services.</p>
                    <p>Our Services - this site and all our software or devices</p>
                    <p>Our Site - site located on <Link className={"header-link"} to={"/"}>https://teslasoft.org</Link>.</p>

                    <h3>Which data we use and why?</h3>

                    <ul>
                        <li>Your email - to verify your account and contact you.</li>
                        <li>Password of your account (we can not access it).</li>
                        <li>Public user name - this name are public and you account can be found by using this name.
                        </li>
                        <li>Photo (avatar) - to help others users to recognize you (optional).</li>
                        <li>Account ID - after registration your account gets unique ID.</li>
                        <li>Date of birth (optional) - some our Services have age-restricted content.</li>
                        <li>Your real (legal) name/surname - We can use this info to identify you in case of your
                            account
                            get locked or hacked.
                        </li>
                        <li>Phone number (optional) - contact info and account verification. You can change visibility
                            of
                            this parameter.
                        </li>
                        <li>Your IP (to identify your device) - we use this param to prevent spam and fraud. We do not
                            allow to use VPN or GPS-spoofers. We never use it to track you.
                        </li>
                        <li>Device info (Model, OS version and device ID (not IMEI) - required for security purposes (to
                            detect suspicious activity and prevent your account from being hacked).
                        </li>
                        <li>Your location - our Services are geographically restricted and can not be used in some
                            countries. Additionally some services may show you location-based suggestions. You can
                            disable
                            this permission anytime.
                        </li>
                        <li>Other info linked to our Services like content that you saved to your account from other
                            apps to
                            sync you apps across your devices.
                        </li>
                        <li>Analytics and personalization (As we are using Google Services you are automatically accept{' '}
                            <a className={"header-link"} href={"https://policies.google.com/privacy"}
                               target={"_blank"} rel="noreferrer">Google's Privacy Policy and Terms of Service</a>.
                        </li>
                    </ul>

                    <div className={"note"}>
                        <span className={"material-symbols-outlined"}>info</span>&nbsp;&nbsp;&nbsp;
                        <span>See "Per app privacy" section to learn when we collect your data.</span>
                    </div>
                    <br/>

                    <h3>Where we store your data?</h3>

                    <p>We're using DigitalOcean and Google Cloud servers to store your data.</p>


                    <h3>Data Security</h3>

                    <ul>
                        <li>We use the latest security technologies like Cloudflare to protect our servers against
                            unwanted users and bots.
                        </li>
                        <li>Your data is backed up regularly.</li>
                        <li>You can request to delete your data.</li>
                        <li>We can not see your passwords or sensitive security info.</li>
                        <li>All security processes runs only on the servers. Apps is just comfortable way to show your
                            data.
                        </li>
                    </ul>

                    <h3>Your rights and commitments</h3>

                    <ul>
                        <li>You can request data deletion for legal purposes.</li>
                        <li>You're granting us an exclusive, royalty-free and world-wide license to store/modify and
                            reproduce your data in our Services.
                        </li>
                        <li>You may not tamper with our Services to steal/delete/share data or in other purposes.</li>
                        <li>You're fully responsible for your data. We do not allow to publish illegal content.</li>
                    </ul>

                    <h3>Our rights and commitments</h3>

                    <ul>
                        <li>We may change this conditions at any time without notifying you.</li>
                        <li>We can delete your data without explaining reasons and notification (For example if your
                            data
                            infringe laws).
                        </li>
                        <li>We obliged to protect your data. We can not share your data if it isn't foreseen by Services
                            or
                            laws. We can't develop Services that share your private data (like IP, phone, real name,
                            etc).
                        </li>
                    </ul>


                    <h3>Our sites</h3>

                    <p>We use Google analytics to analyze site popularity and make your experience better.</p>


                    <h3>Teslasoft Core</h3>

                    <p>Teslasoft Core allow you to sign in to other apps with Teslasoft ID so account and device may be
                        collected when you use it. Also it allow you to sync your data between apps so settings of our
                        apps
                        may
                        be saved to your account when you turned sync on. When you sign in/create an account/change
                        account
                        info
                        your IP address and location will be automatically collected to prevent misuse and protect your
                        account.
                        This app can also collect information about how you are use our apps or apps from third-party
                        developers
                        that uses our APIs and SDKs. This is required to provide ability to sign into apps and enable
                        sync.
                        To prevent misuse of our services this app can also initiate device integrity check and request
                        license
                        check for our other apps. During this check some device information (like Android ID, Android
                        version,
                        device manufacturer can be collected and sent to Google).</p>


                    <h3>Site</h3>

                    <p>When you use our site analytics may be recorded after you clicked accept cookies. Learn more
                        about cookies an why we use it <Link className={"header-link"} to={"/cookies"}>here</Link>. When you use web version of account settings your account info
                        may be collected to show you. This app supports Teslasoft ID sign in and app sync. By using this app
                        you are automatically agree with the policy for Teslasoft Core app.</p>

                    <h3>iVisit</h3>

                    <p>iVisit will help you to travel easier. To show you near places and suggestions it may collect
                        your
                        location. This info is not saved on our servers. If you want you can turn sync on (to do this
                        you
                        need Teslasoft Core to be installed). When you use this app history and analytics is not
                        recorded.
                        This app supports Teslasoft ID sign in and app sync. By using this app you are automatically
                        agree
                        with the policy for Teslasoft Core app.</p>

                    <p>"Contains ads" label may be shown on the Play Store page because this app uses Firebase API that
                        automatically adds "ADS_ID" permission. Firebase is used for notifying you about near events
                        (currently this function is available only for internal testers). No Firebase analytics is
                        collected.
                        No ads are placed in this app.</p>


                    <h3>RGB controller</h3>

                    <p>This app allow you to manage IoT devices. All info about devices are stored locally unless you
                        turn
                        sync on. Also when you use this app, additional info may be collected by manufacturer of your
                        IoT
                        devices. When sync is on app data can be stored in your account. When you use this app history
                        and
                        analytics is not recorded. This app supports Teslasoft ID sign in and app sync. By using this
                        app
                        you are automatically agree with the policy for Teslasoft Core app.</p>


                    <h3>Simple AES256 Tools</h3>

                    <p>This app does not require internet and do not save your data. Sync is not available for this
                        app.</p>


                    <h3>Teslasoft SmartCard</h3>

                    <p>This app allow you to use NFC tags to authenticate. Login info can be saved on the server of your
                        SmartCard provider that you entered in the settings. We are not responsible for that data.
                        Please
                        do not use unsafe servers because your personal info may be leaked. When sync is on app settings
                        can be stored in your account. When you use this app history and analytics is not recorded.
                        Security info (like device model and android ID) may be collected for security purposes and
                        sent to your SmartCard provider. This app supports Teslasoft ID sign in and app sync.
                        By using this app you are automatically agree with Privacy Policy and Terms of Service
                        for Teslasoft Core app.</p>

                    <h3>SpeakGPT</h3>

                    <p>This app requires third-party OpenAI API that can collect additional data such as your messages
                        within app or user id. When sync is on your conversations may be saved in your Teslasoft ID
                        account.
                        Please read <a className={"header-link"} href={"https://openai.com/policies/privacy-policy/"}
                                       target={"_blank"} rel="noreferrer">OpenAI Privacy Policy</a>.</p>

                    <div className={"note"}>
                        <span className={"material-symbols-outlined"}>info</span>
                        &nbsp;&nbsp;&nbsp;
                        <div>
                            <h3>Starting from SpeakGPT version 3.7:</h3>
                            <ul>
                                <li>We may collect analytics data to better understand our audience. We use Google
                                    Analytics
                                    (Google Privacy Policy applies). You can disable analytics in app settings.
                                </li>
                                <li>To continue developing we had to add a few ads. Depending on your Google account
                                    settings, location and preferences ads can be personalized. To opt-out from ads
                                    personalization, go to the Device settings &gt; Google &gt; Ads and select Reset or
                                    delete advertising ID. Also you may need to opt-out from ads personalization in your
                                    Google account.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <br/>
                    <div className={"warning"}>
                        <span className={"material-symbols-outlined"}>warning</span>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                        If you want to request data deletion please contact us via <a className={"header-link"}
                                                                                      href={"mailto:admin@teslasoft.org"}>admin@teslasoft.org</a> (Usually
                        most of your data will be deleted after you close your account. But some anonymous data may stay
                        in our services. To close your account go to Device settings &gt; Accounts and passwords &gt; select
                        an account &gt; click "Account settings" &gt; navigate to security tab &gt; select "Delete my account".
                        Please note that this will permanently delete account without further ability to recover it.
                        We do not retain account info after account is deleted.). If you want to close your Teslasoft
                        SmartCard account please contact your IT administrator. To delete data of specified service
                        use "Delete a service" function in security tab in your account settings.
                    </span>
                    </div>
                </div>
            </TextBlock>
            <Footer/>
        </div>
    );
}

export default Privacy;