import React from 'react';

function MissionBlock({children}) {
    return (
        <div className={"block-text"} id={"block-text"}>
            <div className={"resizeable-container"}>
                {children}
            </div>
        </div>
    );
}

export default MissionBlock;