import React from 'react';
import {BrowserView, MobileView} from "react-device-detect";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className={"footer"} id={"block-footer"}>
            <div className={"footer-divider"}>

            </div>
            <div className={"resizeable-container"}>
                <div className={"footer-table-space"}>
                    <BrowserView>
                        <table style={{
                            borderCollapse: "collapse",
                            width: "100%",
                        }}>
                            <tbody>
                            <tr>

                                <td style={{
                                    width: "50%",
                                }} className={"footer-space"}>
                                    <div className={"footer-link-space"}>
                                        <a href={"/#block-intro"} className={"footer-link"}>About Us</a>
                                        <Link to={"/join"} className={"footer-link"}>Join Us</Link>
                                        <Link to={"/projects"} className={"footer-link"}>Our Projects</Link>
                                        <Link to={"/privacy"} className={"footer-link"}>Privacy Policy</Link>
                                        <Link to={"/tos"} className={"footer-link"}>Terms of Service</Link>
                                    </div>
                                </td>
                                <td style={{
                                    width: "50%",
                                }} className={"footer-space"}>
                                    <div className={"footer-link-space"}>
                                        <a target={"_blank"} href={"https://github.com/AndraxDev"} className={"footer-link"} rel="noreferrer">GitHub</a>
                                        <Link to={"/faq"} className={"footer-link"}>FAQ</Link>
                                        <Link to={"/contact"} className={"footer-link"}>Contact</Link>
                                        <Link to={"/sitemap"} className={"footer-link"}>Site map</Link>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </BrowserView>
                    <MobileView>
                        <table style={{
                            borderCollapse: "collapse",
                            width: "100%",
                        }}>
                            <tbody>
                            <tr>
                                <td style={{
                                    width: "100%",
                                }} className={"footer-space"}>
                                    <div className={"footer-link-space"}>
                                        <a href={"/#block-intro"} className={"footer-link"}>About Us</a>
                                        <Link to={"/join"} className={"footer-link"}>Join Us</Link>
                                        <Link to={"/projects"} className={"footer-link"}>Our Projects</Link>
                                        <Link to={"/privacy"} className={"footer-link"}>Privacy Policy</Link>
                                        <Link to={"/tos"} className={"footer-link"}>Terms of Service</Link>
                                        <a target={"_blank"} href={"https://github.com/AndraxDev"} className={"footer-link"} rel="noreferrer">GitHub</a>
                                        <Link to={"/faq"} className={"footer-link"}>FAQ</Link>
                                        <Link to={"/contact"} className={"footer-link"}>Contact</Link>
                                        <Link to={"/sitemap"} className={"footer-link"}>Site map</Link>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </MobileView>
                </div>
            </div>
        </div>
    );
}

export default Footer;