import React from 'react';
import Header from "../blocks/Header";
import PromoBlock from "../blocks/PromoBlock";
import ProjectsBlock from "../blocks/ProjectsBlock";
import MissionBlock from "../blocks/MissionBlock";
import MembersBlock from "../blocks/MembersBlock";
import ContactBlock from "../blocks/ContactBlock";
import Footer from "../blocks/Footer";

function Home(props) {
    return (
        <>
            <Header/>
            <PromoBlock title={"Teslasoft"} intro={"At Teslasoft, we are dedicated to creating innovative\n" +
                "                            applications that enhance the quality of life for our users. Our team is passionate about\n" +
                "                            crafting digital solutions that are not only functional but also meaningful, aiming to\n" +
                "                            simplify everyday challenges with cutting-edge technology."}/>
            <ProjectsBlock/>
            <MissionBlock/>
            <MembersBlock/>
            <ContactBlock/>
            <Footer/>
        </>
    );
}

export default Home;