import React from 'react';
import Header from "../blocks/Header";
import PromoBlock from "../blocks/PromoBlock";
import TextBlock from "../blocks/TextBlock";
import Footer from "../blocks/Footer";

function Faq() {
    return (
        <div>
            <Header/>
            <PromoBlock title={"Frequently Asked Questions"} intro={"Here we periodically publish most active user questions."}/>
            <TextBlock>
                <div className={"text-container"}>
                    <h3>Nothing here yet...</h3>
                    <p>...unless the next project meeting :)</p>
                </div>
            </TextBlock>
            <Footer/>
        </div>
    );
}

export default Faq;