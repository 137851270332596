import React from 'react';
import TextBlock from "./TextBlock";

function ProjectBlockTemplate({title, icon, cover, desc, children}) {
    return (
        <>
            <div className={"block-promo"}>
                <div className={"intro-background"} style={{
                    backgroundImage: `url(${cover})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed"
                }}>

                </div>
                <div className={"intro-dim"}>
                    <div className={"resizeable-container"}>
                        <div style={{
                            height: "64px"
                        }}></div>
                        <div className={"intro-block"}>
                            <img alt={title} src={icon} className={"project-icon"}/>
                            <br/>
                            <h1 className={"promo-title"}>{title}</h1>
                            <br/>
                            <p className={"promo-paragraph"}>{desc}</p>
                        </div>
                    </div>
                </div>
            </div>
            <TextBlock>
                <div className={"text-container"}>
                    {children}
                </div>
            </TextBlock>
        </>
    );
}

export default ProjectBlockTemplate;