import React from 'react';
import Header from "../blocks/Header";
import PromoBlock from "../blocks/PromoBlock";
import Footer from "../blocks/Footer";
import TextBlock from "../blocks/TextBlock";

function ToS() {
    return (
        <div>
            <Header />
            <PromoBlock title={"Terms of Service"} />
            <TextBlock>
                <div className={"text-container"}>
                    <h3>1. General terms</h3>

                    <p>We - organization "Teslasoft" registered at Južná trieda, Košice, Slovakia (Registration number:
                        55545386, D-U-N-S: 933739642).</p>

                    <p>Teslasoft product - any our software, service or physical device developed by us.</p>

                    <p>While you are under 18 you need explicit permission from your parents to use our Products.</p>

                    <p>While you are under 13 you can not use our products in any way.</p>


                    <h3>2. License</h3>

                    <p>By receiving, opening the file package, and/or using Teslasoft Software ("Products") containing
                    this software, you agree that this End User User License Agreement(EULA) is a legally binding
                    and valid contract and agree to be bound by it. You agree to abide by the intellectual property
                    laws and all of the terms and conditions of this Agreement.</p>

                    <p>Unless you have a different license agreement signed by Teslasoft your use of Products indicates
                    your acceptance of this license agreement and warranty.</p>

                    <p>Subject to the terms of this Agreement, Teslasoft grants to you a limited, non-exclusive,
                    non-transferable license, without right to sub-license, to use our Products in accordance with
                    this Agreement and any other written agreement with Teslasoft. Teslasoft does not transfer the
                    title of our Products to you; the license granted to you is not a sale. This agreement is a
                    binding legal agreement between Teslasoft and the purchasers or users of our Products.</p>

                    <p>If you do not agree to be bound by this agreement, remove our Products (if applicable) from your
                    computer now and, if applicable, promptly return to Teslasoft by mail any copies of our Products,
                    devices and related documentation and packaging in your possession.</p>


                    <h3>3. Distribution</h3>

                    <p>Our products and the license herein granted shall not be copied, shared, distributed, re-sold,
                    offered for re-sale, transferred or sub-licensed in whole or in part except that you may make
                    one copy for archive purposes only (if applicable). For information about redistribution of
                    our Products contact Teslasoft.</p>


                    <h3>4. User agreements</h3>

                    <h4>4.1 Use</h4>

                    <p>Your license to use our Products is limited to the number of licenses purchased by you or in
                    other way got to you by Teslasoft. You shall not allow others to use, copy or evaluate copies
                    of our Products without written permission of Teslasoft.</p>


                    <h4>4.2 Use Restrictions</h4>

                    <p>You shall use our Products in compliance with all applicable laws and not for any unlawful
                    purpose. Without limiting the foregoing, use, display or distribution of our Products together
                    with material that is pornographic, racist, vulgar, obscene, defamatory, libelous, abusive,
                    promoting hatred, discriminating or displaying prejudice based on religion, ethnic heritage,
                    race, sexual orientation or age is strictly prohibited.</p>

                    <p>Each licensed copy of our Product may be used on one single computer location (if applicable) by
                    one user. Use of our Products means that you have loaded, installed, or run our Products on a
                    computer or similar device. If you install our Products onto a multi-user platform, server or
                    network, each and every individual user of our Products must be licensed separately.</p>

                    <p>You may make one copy of our Products for backup purposes, providing you only have one copy
                    installed on one computer being used by one person. Other users may not use your copy of our
                    Products. The assignment, sublicense, networking, sale, or distribution of copies of our Products
                    are strictly forbidden without the prior written consent of Teslasoft. It is a violation of this
                    agreement to assign, sell, share, loan, rent, lease, borrow, network or transfer the use of our
                    Products. If any person other than yourself uses our Products registered in your name, regardless
                    of whether it is at the same time or different times, then this agreement is being violated and
                    you are responsible for that violation!</p>


                    <h4>4.3 Copyright Restriction</h4>

                    <p>This Software contains copyrighted material, trade secrets and other proprietary material. You
                    shall not, and shall not attempt to, modify, reverse engineer, disassemble, remove any seals
                    (if applicable) or decompile our Products. Nor can you create any derivative works or other
                    works that are based upon or derived from our Products in whole or in part.</p>

                    <p>Teslasoft's name, logo and graphics file that represents our Products shall not be used in any
                    way to promote products developed with our Products. Teslasoft retains sole and exclusive ownership
                    of all right, title and interest in and to our Products and all Intellectual Property rights
                    relating thereto.</p>

                    <p>Copyright law and international copyright treaty provisions protect all parts of our Products,
                    products and services. No program, code, part, image, audio sample, or text may be copied or
                    used in any way by the user except as intended within the bounds of the single user program.
                    All rights not expressly granted hereunder are reserved for Teslasoft.</p>


                    <h4>4.4 Limitation of Responsibility</h4>

                    <p>You will indemnify, hold harmless, and defend Teslasoft, its employees, agents and distributors
                    against any and all claims, proceedings, demand and costs resulting from or in any way
                    connected with your use of Teslasoft's Software.</p>

                    <p>In no event (including, without limitation, in the event of negligence) will Teslasoft, its
                    employees, agents or distributors be liable for any consequential, incidental, indirect,
                    special or punitive damages whatsoever (including, without limitation, damages for loss of
                    profits, loss of use, business interruption, loss of information or data, or pecuniary loss),
                    in connection with or arising out of or related to this Agreement, our Products or the use or
                    inability to use our Products or the furnishing, performance or use of any other matters
                    hereunder whether based upon contract, tort or any other theory including negligence.</p>

                    <p>Teslasoft's entire liability, without exception, is limited to the customers reimbursement
                    of the purchase price of the Software (maximum being the lesser of the amount paid by you
                    and the suggested retail price as listed by Teslasoft) in exchange for the return of the
                    product, all copies, registration papers and manuals, and all materials that constitute a
                    transfer of license from the customer back to Teslasoft.</p>


                    <h4>4.5 Warranties</h4>

                    <p>Except as expressly stated in writing, Teslasoft makes no representation or warranties in
                    respect of this Software and expressly excludes all other warranties, expressed or implied,
                    oral or written, including, without limitation, any implied warranties of merchantable
                    quality or fitness for a particular purpose.</p>


                    <h4>4.6 Governing Law</h4>

                    <p>This Agreement shall be governed by the law of the Slovakia applicable therein. You hereby
                    irrevocably attorn and submit to the non-exclusive jurisdiction of the courts of the EU
                    therefrom. If any provision shall be considered unlawful, void or otherwise unenforceable,
                    then that provision shall be deemed severable from this License and not affect the validity
                    and enforceability of any other provisions.</p>


                    <h4>4.7 Termination</h4>

                    <p>Any failure to comply with the terms and conditions of this Agreement will result in automatic
                    and immediate termination of this license. Upon termination of this license granted herein for
                    any reason, you agree to immediately cease use of our Products and destroy all copies of our
                    Products supplied under this Agreement. The financial obligations incurred by you shall
                    survive the expiration or termination of this license.</p>


                    <h3>5. DISCLAIMER OF WARRANTY</h3>

                    <p>THIS SOFTWARE AND THE ACCOMPANYING FILES ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES AS TO
                    PERFORMANCE OR MERCHANTABILITY OR ANY OTHER WARRANTIES WHETHER EXPRESSED OR IMPLIED. THIS
                    DISCLAIMER CONCERNS ALL FILES GENERATED AND EDITED BY OUR PRODUCTS AS WELL.</p>


                    <h3>6. CONSENT OF USE OF DATA</h3>

                    <p>You agree that Teslasoft may collect and use information gathered in any manner as part of
                    the product support services provided to you, if any, related to our Products. Teslasoft
                    may also use this information to provide notices to you which may be of use or interest
                    to you. Read Privacy Policy (https://teslasoft.org/privacy) to learn more.</p>


                    <h3>7. SpeakGPT</h3>

                    <p>SpeakGPT allows you to upload prompts for GPT (Generative-pretrained-transformer). By uploading
                    prompt you are automatically agree that Teslasoft may process your data (modify/remove/create
                    derivations/etc.)</p>


                    <h4>7.1. Abusive Content</h4>

                    <p>Teslasoft may remove any abusive content without further notice and block you from uploading
                    prompts. You can report inappropriate content by clicking "flag" icon on the content page.</p>
                </div>
            </TextBlock>
            <Footer/>
        </div>
    );
}

export default ToS;