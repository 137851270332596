import React from 'react';
import {BrowserView, MobileView} from "react-device-detect";
import {Link} from "react-router-dom";

function ProjectCard({projectBackground, projectIcon, projectTitle, projectDescription, projectLink, side}) {
    return (
        <>
            <BrowserView>
                {
                    side === "left" ? <table style={{
                        width: "100%",
                        borderCollapse: "collapse"
                    }}>
                        <tbody>
                        <tr>
                            <td className={"project-table-element"}>
                                <div className={"project-background"} style={{
                                    backgroundImage: `url(${projectBackground})`
                                }}>
                                    <div className={"project-background-dim project-background"}>
                                        <img src={projectIcon} alt={"project"} className={"project-icon"}/>
                                        <h3 className={"project-title"}>{projectTitle}</h3>
                                    </div>
                                </div>
                            </td>
                            <td className={"project-table-element"}>
                                <div className={"project-info"}>
                                    <p className={"project-description"}>{projectDescription}</p>
                                    <br/>
                                    <Link to={projectLink}>
                                        <button className={"btn-material-tonal btn-material"}>More info</button>
                                    </Link>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table> : <table style={{
                        width: "100%",
                        borderCollapse: "collapse"
                    }}>
                        <tbody>
                        <tr>
                            <td className={"project-table-element"}>
                                <div className={"project-info"}>
                                    <p className={"project-description"}>{projectDescription}</p>
                                    <br/>
                                    <Link to={projectLink}>
                                        <button className={"btn-material-tonal btn-material"}>More info</button>
                                    </Link>
                                </div>
                            </td>
                            <td className={"project-table-element"}>
                                <div className={"project-background"} style={{
                                    backgroundImage: `url(${projectBackground})`
                                }}>
                                    <div className={"project-background-dim project-background"}>
                                        <img src={projectIcon} alt={"project"} className={"project-icon"}/>
                                        <h3 className={"project-title"}>{projectTitle}</h3>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                }
            </BrowserView>
            <MobileView>
                <table style={{
                    width: "100%",
                    borderCollapse: "collapse"
                }}>
                    <tbody>
                    <tr>
                        <td className={"project-table-element"}>
                            <div className={"project-background"} style={{
                                backgroundImage: `url(${projectBackground})`,
                                borderRadius: "24px"
                            }}>
                                <div className={"project-background-dim project-background"} style={{
                                    borderRadius: "24px"
                                }}>
                                    <img src={projectIcon} alt={"project"} className={"project-icon"}/>
                                    <h3 className={"project-title"}>{projectTitle}</h3>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={"project-table-element"}>
                            <div className={"project-info"}>
                                <p className={"project-description"}>{projectDescription}</p>
                                <br/>
                                <Link to={projectLink}>
                                    <button className={"btn-material-tonal btn-material"}>More info</button>
                                </Link>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </MobileView>
        </>
    );
}

export default ProjectCard;