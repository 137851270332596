import React from 'react';
import {sha256} from "js-sha256";
import { getDatabase, ref, set } from "firebase/database";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBtOcf0qsjc73417wOg2QnKKjLpCLHGCvE",
    authDomain: "jarvis-a301b.firebaseapp.com",
    databaseURL: "https://jarvis-a301b.firebaseio.com",
    projectId: "jarvis-a301b",
    storageBucket: "jarvis-a301b.appspot.com",
    messagingSenderId: "1083710434382",
    appId: "1:1083710434382:web:4d29cdb2f948a66d8f271f",
    measurementId: "G-MDB3Z3T5XS"
};

const app = initializeApp(firebaseConfig);

const database = getDatabase();

function ContactBlock() {
    return (
        <div className={"block-contact"} id={"block-contact"}>
            <div className={"resizeable-container"}>
                <h1 className={"header-subtitle"}>Contact Us</h1>
                <form className={"contact-form"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <input id={"first-name"} style={{
                            width: "calc(50% - 40px)",
                            marginRight: "8px"
                        }} className={"field-material"} placeholder={"First Name"}/>
                        <input id={"last-name"} style={{
                            width: "calc(50% - 40px)",
                            marginLeft: "8px"
                        }} className={"field-material"} placeholder={"Last Name"}/>
                    </div>
                    <input id={"email"} style={{
                        width: "calc(100% - 32px)",
                        marginTop: "16px"
                    }} className={"field-material"} placeholder={"Email"}/>
                    <textarea id={"message"} style={{
                        width: "calc(100% - 32px)",
                        marginTop: "16px",
                        maxWidth: "calc(100% - 32px)",
                        minWidth: "calc(100% - 32px)",
                        minHeight: "180px",
                        maxHeight: "240px",
                    }} className={"field-material"} placeholder={"Message..."}/>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "16px",
                        paddingBottom: "16px",
                    }}>
                        <button className={"btn-material-tonal btn-material"} onClick={(e) => {
                            e.preventDefault();
                            let firstName = document.getElementById("first-name").value;
                            let lastName = document.getElementById("last-name").value;
                            let email = document.getElementById("email").value;
                            let message = document.getElementById("message").value;

                            if (firstName.length < 1 || lastName.length < 1 || email.length < 1 || message.length < 1) {
                                alert("Please fill in all fields.");
                                return;
                            }

                            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                            if (!emailRegex.test(email)) {
                                alert("Invalid email address.");
                                return;
                            }

                            // Automatically rejects duplicated messages to prevent database pollution.
                            // Message id depends on senders email and contents so it impossible to save 2 identical messages.
                            let messageId = sha256(email + " " + message).toString();

                            let messageData = {
                                id: messageId,
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                                message: message,
                            };

                            set(ref(database, 'contactform/' + messageId), messageData)
                                .then(r => {
                                    alert("Message sent successfully!");
                                    document.getElementById("first-name").value = "";
                                    document.getElementById("last-name").value = "";
                                    document.getElementById("email").value = "";
                                    document.getElementById("message").value = "";
                                })
                                .catch(e => {
                                    alert("Failed to send message. Please try again later.");
                                });
                        }}>Send message</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactBlock;