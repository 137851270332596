import React from 'react';
import Header from "../blocks/Header";
import PromoBlock from "../blocks/PromoBlock";
import Footer from "../blocks/Footer";
import TextBlock from "../blocks/TextBlock";

function Cookies() {
    return (
        <div>
            <Header />
            <PromoBlock title={"Cookies"} intro={"Cookies are temporary files that are stored on your computer to store small amounts of information (ex. your language preferences, appearance settings). For example, you are signed in to your account. But on page reload, the session was not terminated. The point is that the login information was saved in the cookies."} />
            <TextBlock>
                <div className={"text-container"}>
                    <h3>Why do we use id?</h3>
                    <ul>
                        <li>Storing information about your login session (We NEVER store your username and password in cookies).</li>
                        <li>Analytics and personalization (see Google Privacy Policy and Terms of Services).</li>
                        <li>For security (AntiBot.Cloud, session identifiers)</li>
                    </ul>
                    <div className={"warning"}>
                        <span className={"material-symbols-outlined"}>warning</span>&nbsp;&nbsp;&nbsp;<span>WE DO NOT USE COOKIES TO TRACK PERSONAL USER DATA SUCH AS IP ADDRESSES, GEOLOCATION, PASSWORDS, DATE OF BIRTH, REAL NAME, ETC.</span>
                    </div>
                </div>
            </TextBlock>
            <Footer/>
        </div>
    );
}

export default Cookies;