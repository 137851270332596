import React from 'react';
import {BrowserView, MobileView} from "react-device-detect";

function Assistant() {
    return (
        <>
            <BrowserView className={"assistant-desktop"}>
                <iframe title={"SpeakGPT Quick Assistant"} src="https://assistant.teslasoft.org/embedded" className="assistant-iframe"></iframe>
            </BrowserView>
            <MobileView className={"assistant-mobile"}>
                <iframe title={"SpeakGPT Quick Assistant"} src="https://assistant.teslasoft.org/embedded" className="assistant-iframe"></iframe>
            </MobileView>
        </>
    );
}

export default Assistant;