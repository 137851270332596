import React from 'react';
import Header from "../blocks/Header";
import PromoBlock from "../blocks/PromoBlock";
import Footer from "../blocks/Footer";
import TextBlock from "../blocks/TextBlock";
import ProjectCard from "../blocks/ProjectCard";
import logo from "../res/brand_dark.png";

function Projects() {
    return (
        <div>
            <Header/>
            <PromoBlock title={"Projects"} intro={"Here you can see our projects."}/>
            <TextBlock>
                <div>
                    <ProjectCard projectLink={"/projects/speakgpt"} projectBackground={"https://teslasoft.org/res/new/sp_cover.png"}
                                 projectIcon={"https://teslasoft.org/res/new/speakgpt.webp"} projectTitle={"SpeakGPT"}
                                 projectDescription={"SpeakGPT is an advanced, highly intuitive AI-powered chatbot and voice assistant that harnesses the advanced capabilities of OpenAI's ChatGPT and DALL·E technologies."}
                                 side={"left"}/>
                    <ProjectCard projectLink={"/projects/ivisit"} projectBackground={"https://teslasoft.org/res/new/kosice.jpg"}
                                 projectIcon={"https://teslasoft.org/res/new/ivisit.png"} projectTitle={"iVisit"}
                                 projectDescription={"Discover cities like never before with iVisit, your ultimate travel companion in the palm of your hand. This user-friendly app brings your adventures to life with live suggestions and detailed insights into must-visit spots."}
                                 side={"right"}/>
                    <ProjectCard projectLink={"/projects/teslasoft-id"} projectBackground={"https://teslasoft.org/res/new/app_banner.webp"} projectIcon={logo}
                                 projectTitle={"Teslasoft ID"}
                                 projectDescription={"Simplify your digital life with Teslasoft ID, a seamless Single Sign-On (SSO) solution that lets you access all your accounts with just one login."}
                                 side={"left"}/>
                </div>
            </TextBlock>
            <Footer/>
        </div>
    );
}

export default Projects;