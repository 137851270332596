import React from 'react';
import Header from "../blocks/Header";
import PromoBlock from "../blocks/PromoBlock";
import Footer from "../blocks/Footer";
import ContactBlock from "../blocks/ContactBlock";

function Contact() {
    return (
        <div>
            <Header/>
            <PromoBlock title={"Contact"} intro={"Have any questions? Send us a message. Usually, it can take 1-2 business days to response."}/>
            <ContactBlock/>
            <Footer/>
        </div>
    );
}

export default Contact;