import React, {useEffect} from 'react';

function Debug() {
    useEffect(() => {
        console.log("Debug");
        console.log("Clearing window state...");
        localStorage.windows = '[]';
        localStorage.count = '0';
        localStorage.location = '';
        window.location.assign("/");
    }, []);
    return (
        <div><p className={"text-regular"}>Clearing windows state...</p></div>
    );
}

export default Debug;