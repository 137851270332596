import React from 'react';
import logo from '../res/brand_dark.png';
import {BrowserView, MobileView} from "react-device-detect";
import {Link} from "react-router-dom";
import Assistant from "./Assistant";

function Header() {
    let [mobileMenuOpened, setMobileMenuOpened] = React.useState(false);
    let [assistantIsOpened, setAssistantIsOpened] = React.useState(false);

    return (
        <>
            {assistantIsOpened ? <Assistant/> : null}
            <div className={"header-background"} id={"block-header"}>
                <header className={"header resizeable-container"}>
                    <BrowserView>
                        <div className={"header-left"}>
                            <Link className={"header-link"} to={"/"}><img src={logo} alt="logo"
                                                                          className={"header-logo"}/></Link>
                            &nbsp;&nbsp;
                            <a className={"header-link"} href={"/#block-projects"}>Our Projects</a>
                            &nbsp;&nbsp;
                            <a className={"header-link"} href={"/#block-intro"}>About Us</a>
                            &nbsp;&nbsp;
                            <a className={"header-link"} href={"/#block-mission"}>Our Mission</a>
                            &nbsp;&nbsp;
                            <a className={"header-link"} href={"/#block-contact"}>Contact Us</a></div>
                    </BrowserView>
                    <MobileView>
                        <div className={"header-left"}>
                            <Link className={"header-link"} to={"/"}><img src={logo} alt="logo"
                                                                          className={"header-logo"}/></Link>
                            <button style={{
                                border: "none",
                                background: "none",
                            }} onClick={() => {
                                setMobileMenuOpened(!mobileMenuOpened);
                            }}>
                            <span style={{
                                color: "#fff",
                                fontSize: "32px",
                            }} className="material-symbols-outlined">{mobileMenuOpened ? "close" : "menu"}</span>
                            </button>
                        </div>

                    </MobileView>
                    <button className={"header-speakgpt"} onClick={() => {
                        setAssistantIsOpened(!assistantIsOpened);
                    }}><img className={"speakgpt-button-icon"} src={"https://teslasoft.org/res/new/speakgpt.webp"}/>
                    </button>
                </header>
            </div>
            <MobileView>
                <div className={"mobile-menu"} style={{
                    width: mobileMenuOpened ? "calc(80vw)" : "0",
                    transform: mobileMenuOpened ? "translateX(0)" : "translateX(-50%)",
                }}>
                    <div style={{
                        transform: mobileMenuOpened ? "translateX(0)" : "translateX(-100%)",
                    }} id={"mobile-menu-content"} className={"mobile-menu-content"}>
                        <a style={{
                            opacity: mobileMenuOpened ? "1" : "0",
                        }} onClick={() => {
                            setMobileMenuOpened(false)
                        }} className={"header-link-mobile header-link"} href={"/#block-projects"}>Our
                            Projects</a>
                        <a style={{
                            opacity: mobileMenuOpened ? "1" : "0",
                        }} onClick={() => {
                            setMobileMenuOpened(false)
                        }} className={"header-link-mobile header-link"} href={"/#block-intro"}>About Us</a>
                        <a style={{
                            opacity: mobileMenuOpened ? "1" : "0",
                        }} onClick={() => {
                            setMobileMenuOpened(false)
                        }} className={"header-link-mobile header-link"} href={"/#block-mission"}>Our Mission</a>
                        <a style={{
                            opacity: mobileMenuOpened ? "1" : "0",
                        }} onClick={() => {
                            setMobileMenuOpened(false)
                        }} className={"header-link-mobile header-link"} href={"/#block-contact"}>Contact Us</a>
                    </div>
                </div>
            </MobileView>
        </>
    );
}

export default Header;